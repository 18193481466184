import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout.js"
import PostList from "../components/posts/post-list.js"
import ThemeProviderComponent from "../components/ThemeProvider.js"
import WelcomeHeader from "../components/WelcomeHeader/welcome-header"
import SEO from "../components/SEO.js"
import Newsletter from "../components/NewsletterForm"

var { useEffect, useState, useContext } = React

const PageTemplate = props => {
  var {
    allMdx: { edges },
  } = props.data

  // useEffect(() => {
  //   console.log("Houston we have mounted the web!")
  //   setState(() => (state = "dark"))
  // }, [])
  console.log("COVER IMAGE SHARP", edges[0].node.frontmatter.coverImage)
  return (
    <ThemeProviderComponent>
      <Layout theme="dark">
        <SEO title="Code" />
        <WelcomeHeader />
        <PostList posts={edges} />
        <Newsletter />
      </Layout>
    </ThemeProviderComponent>
  )
}

export default PageTemplate

export const getPosts = graphql`
  query($category: String) {
    allMdx(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: frontmatter___pubId, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            category
            published
            project
          }
          body
        }
      }
    }
  }
`

// coverImage {
//   childImageSharp {
//     fluid {
//       ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }
